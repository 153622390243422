@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500;700&family=Rajdhani:wght@700&display=swap');

html, body {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

body {
    background: linear-gradient(135deg, rgb(216, 236, 255) 0%, rgb(182, 219, 193) 100%);
}

.note, .date {
    z-index: 99999;
    transform: rotate(-10deg);
    font-family: 'Caveat', cursive;
    color: #fff;
    text-shadow: 0 0 20px #000;
}

.note {
    
    position: absolute;
    top: 450px;
    left: 20px;
    font-size: 50px;

}

.date {
    font-family: 'Caveat', cursive;
    font-weight: bold;
    position: absolute;
    top: 360px;
    font-size: 100px;
    left: 65px;

}

.loader {
    width: 100%;
    height: 100%;
    background:  linear-gradient(180deg, rgb(30, 30, 34) 0%, rgba(0,0,0,1) 100%);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.5s;

    &--hidden {
        opacity: 0;
    }

    .start {
        // width: 200px;
        padding: 20px 50px;
        line-height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 'Rajdhani', sans-serif;
        font-size: 50px;
        background: #3532ff;
        color: white;
        border-radius: 100px;
        transition: all 0.15s;
        cursor:pointer;
        user-select: none;
        text-align: center;

        &:hover {
            background: #514efc;
        }

        &:active {
            background: #2623b3;
        }

    }
}



.stage {
    height: 550px;
    width: 1000px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 20px -10px #000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

}

.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    will-change: transform;

    // overflow: hidden;

    div {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        margin: 0;
        padding: 0;

        // &:nth-child(1) { left: 0; }
        // &:nth-child(2) { left:998px; }
        // &:nth-child(3) { left: 1996px; }

    }
}

#pics {
    width: 15000px;
    height: 500px;
    position: absolute;
    top:0;
    left: 0;
    

    div {
        position: absolute;
        width: 250px;
        border: 10px #fff solid;
        border-radius: 3px;
        box-shadow: 0 0 20px -10px #000;
        background-size: contain;
        background-position: center center;
    }
}